import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
	constructor() {
			this.init();
			this.recruitment();
		}

	init() {
		const wrapper = document.querySelector('.js-hero-wrapper');
		const	swiper = new Swiper('.js-hero', {
			speed: 400,
			loop: true,
			slidesPerView: 1.05,
			spaceBetween: 10,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			breakpoints: {
				1008: {
					slidesPerView: 1.16,
					spaceBetween: 40,
				},
			},
			on: {
				init: function () {
					addClass();
				},
				activeIndexChange: function () {
					setTimeout(() => {
						addClass();
					}, 100);
				},
			},
		});
		function addClass() {
			let active = document.querySelector('.swiper-slide-active');
			let num = active.dataset.clr;
			wrapper.dataset.color = num;
		}
	}
	recruitment() {
		const	swiper = new Swiper('.js-recruitment', {
			speed: 6000,
			loop: true,
			slidesPerView: 1.5,
			spaceBetween: 8,
			centeredSlides: true,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
			},
			breakpoints: {
				1008: {
					slidesPerView: 3.5,
					spaceBetween: 16,
				},
			},
		});
	}
}