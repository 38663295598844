import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
	constructor() {
			this.init();
			this.pin();
		}

		init() {
			const fadeItems = gsap.utils.toArray('.js-fadein');
			if (fadeItems) {
				fadeItems.forEach((fadeItem) => {
					ScrollTrigger.create({
						//markers: true,
						trigger: fadeItem, //アニメーションが始まるトリガーとなる要素
						start: 'top 75%',
						end: 'bottom 75%',
						toggleClass: {
							targets: fadeItem,
							className: "is-active"
						},
						once: true
					});
				});
			}
		}

	pin() {
		const pinHeader = document.querySelector('.js-about-header');
		if (pinHeader) {
			let mm = gsap.matchMedia();
			mm.add("(1008px <= width)", () => {
				ScrollTrigger.create({
					trigger: pinHeader,
					pin: true,
					//markers: true,
					start: 'top top',
					end: 'bottom bottom'
				})
			});
		}
	}
}